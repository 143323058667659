// src\store\useReportStore.ts

import { FieldLabel, ReportField, ReportLabel, ReportSection, ReportState, TagFields } from "@/types/report";
import { create } from "zustand";
import { persist } from "zustand/middleware";

// Always completed sections - Dating, Fetal Biometry
const ALWAYS_COMPLETED_SECTIONS = new Set<number>([6, 7]);

export const useReportStore = create<ReportState>()(
  persist(
    (set, get) => ({
      report: [],
      activeReportSection: "General",
      activeReportSectionData: [],
      selectedSections: [],
      selectedHeaderDetails: [],
      completedSections: new Set<number>(),
      filledFields: {},
      textFields: {},
      activeSectionId: 0,
      currentCompleteIndex: 0,
      currentIncompleteIndex: 0,
      lastVisitedCompleteSectionId: 0,
      lastVisitedIncompleteSectionId: 0,
      reportTemplateHeaders: [],
      autofillFields: {},
      isAutofillEnabled: true,

      /**
       * Updates the value of a specific text area field.
       * @param {number} headerId - Section ID.
       * @param {number} labelId - Field ID.
       * @param {string} value - New text value.
       */
      setTextFields: (
        headerId: number,
        labelId: number,
        value: string
      ): void => {
        set((state) => ({
          textFields: {
            ...state.textFields,
            [`${headerId}-${labelId}`]: value,
          },
        }));
      },

      setReportTemplateHeadersInStore: (headers: FieldLabel[]) => {
        set({ reportTemplateHeaders: headers });
      },

      /**
       * Retrieves the stored text field values.
       * @returns {Record<string, string>}
       */
      getTextFields: () => get().textFields,

      /**
       * Sets the active section ID to null when the Progress bar closes.
       */
      resetActiveSection: () => set({ activeSectionId: 0 }), // Add this function

      /**
       * Set the active section by ID for navigation
       */
      setActiveSectionId: (sectionId: number) =>
        set({ activeSectionId: sectionId }),

      /**
       * Navigate to the next or previous complete/incomplete section
       */
      navigateSection: (
        direction: "next" | "prev",
        type: "complete" | "incomplete"
      ) => {
        const {
          completedSections,
          report,
          activeSectionId,
          currentCompleteIndex,
          currentIncompleteIndex,
        } = get();

        const allSections = report.flatMap((tab) =>
          tab.fields.map((section) => section.headerId)
        );

        // Get the relevant section list
        const sectionList =
          type === "complete"
            ? Array.from(completedSections)
            : allSections.filter((id) => !completedSections.has(id));

        // Sort sections to maintain order
        sectionList.sort(
          (a, b) => allSections.indexOf(a) - allSections.indexOf(b)
        );

        if (sectionList.length === 0) return; // No sections to navigate

        // Determine the last visited index based on type
        let lastVisitedIndex =
          type === "complete" ? currentCompleteIndex : currentIncompleteIndex;

        let currentIndex = sectionList.indexOf(
          activeSectionId ??
            sectionList[Math.max(0, lastVisitedIndex - 1)] ??
            sectionList[0]
        );

        // Calculate the new index
        let newIndex =
          direction === "next"
            ? Math.min(currentIndex + 1, sectionList.length - 1)
            : Math.max(currentIndex - 1, 0);

        const newSectionId = sectionList[newIndex];

        // Find the tab that contains the newSectionId
        const newTab = report.find((tab) =>
          tab.fields.some((field) => field.headerId === newSectionId)
        );

        if (newTab) {
          set({
            activeReportSection: newTab.tabname, // Update the tab
            activeReportSectionData: newTab.fields, // Update the fields
            activeSectionId: newSectionId, // Ensure the section ID is updated
          });
        }

        // Scroll smoothly to the new section
        requestAnimationFrame(() => {
          const sectionElement = document.getElementById(
            `section-${newSectionId}`
          );
          if (sectionElement) {
            sectionElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        });

        // Persist last visited index for each type
        set({
          activeSectionId: newSectionId,
          ...(type === "complete"
            ? { currentCompleteIndex: newIndex + 1 }
            : { currentIncompleteIndex: newIndex + 1 }),
        });
      },

      /**
       * Sets the report data and initializes the first section as active.
       * @param {Array} data - Array of report sections.
       */
      setReport: (data: ReportSection[]) => {
        const storedFields = get().filledFields; // Get persisted filled fields
        const storeTextFields = get().textFields; // Get persisted additional fields
        const autofillFields: Record<string, string | string[]> = {};

        const filledFields: Record<string, string | string[]> = {
          ...storedFields,
        };
        const textFields: Record<string, string> = { ...storeTextFields };

        const processLabels = (headerId: number, labels: ReportLabel[]) => {
          labels.forEach((label) => {
            const fieldKey = `${headerId}-${label.labelId}`;
            if (
              !(fieldKey in storedFields) &&
              label.value &&
              label.value.length !== 0
            ) {
              filledFields[fieldKey] = label.value; // Store API pre-filled values
            }
            if (
              !(fieldKey in storeTextFields) &&
              label.textValue &&
              label.textValue.length !== 0
            ) {
              textFields[fieldKey] = label.textValue; // Store API pre-filled text values
            }

            // Extract autofill fields if headerId is between 11-17
            if (headerId >= 11 && headerId <= 17) {
              autofillFields[fieldKey] = filledFields[fieldKey] || label.value;
            }
          });
        };

        const processSubheaders = (field: ReportField) => {
          field.subheaders.forEach((sub) => {
            processLabels(field.headerId, sub.labels);
          });
        };

        const extractFilledFields = (fields: ReportField[]) => {
          fields.forEach((field) => processSubheaders(field));
        };

        data.forEach((tab) => extractFilledFields(tab.fields));

        set({
          report: data,
          activeReportSection: data.length > 0 ? data[0].tabname : "General",
          activeReportSectionData: data.length > 0 ? data[0].fields : [],
          filledFields, // Store pre-filled values
          textFields,
          autofillFields,
        });

        get().updateFilledFields(); // Recalculate completed sections
      },

      /**
       * Enables autofill by copying `autofillFields` into `filledFields`.
       */
      enableAutofill: () => {
        set((state) => ({
          filledFields: { ...state.filledFields, ...state.autofillFields },
          isAutofillEnabled: true,
        })); 
      },

      /**
       * Disables autofill by removing autofill-related fields from `filledFields`,
       * but keeping `autofillFields` intact.
       */
      disableAutofill: () => {
        set((state) => {
          const updatedFilledFields = { ...state.filledFields };

          Object.keys(state.autofillFields).forEach((key) => {
            updatedFilledFields[key] = "";
          });

          return {
            filledFields: updatedFilledFields,
            isAutofillEnabled: false,
          };
        });
      },


      /**
       * Toggles autofill functionality dynamically.
       */
      toggleAutofill: () => {
        const { isAutofillEnabled, enableAutofill, disableAutofill } = get();
        isAutofillEnabled ? disableAutofill() : enableAutofill();
        get().updateFilledFields();
      },

      /**
       * Sets the active report section and its associated data.
       * @param {string} section - The name of the active section.
       */
      setActiveReportSection: (section: string) => {
        const tab = get().report.find((tab) => tab.tabname === section);
        if (tab) {
          set({
            activeReportSection: tab.tabname,
            activeReportSectionData: tab.fields,
          });
        }
      },

      /**
       * Updates the list of selected sections based on their header IDs.
       * @param {number[]} sections - Array of selected section header IDs.
       */
      setSelectedSections: (sections: number[]) =>
        set((state) => {
          const updatedCompletedSections = new Set(state.completedSections);
          const updatedHeaderDetails: TagFields[] = [];

          // Remove unchecked sections from completedSections
          state.completedSections.forEach((sectionId) => {
            if (!sections.includes(sectionId)) {
              updatedCompletedSections.delete(sectionId);
            }
          });

          sections.forEach((sectionId) => {
            const section = findSectionById(state.report, sectionId);
            if (section) {
              updatedHeaderDetails.push({
                id: sectionId,
                label: section.header,
              });
            }
          });

          return {
            selectedSections: sections,
            completedSections: updatedCompletedSections,
            selectedHeaderDetails: updatedHeaderDetails,
          };
        }),

      getFilledValues: () => get().filledFields,

      /**
       * Returns the total number of sections in the report.
       * @returns {number} Total number of sections.
       */
      getTotalSections: (): number => {
        return get().report.reduce(
          (total, tab) => total + tab.fields.length,
          0
        );
      },

      /**
       * Returns the total number of user-selected sections.
       * @returns {number} Count of selected sections.
       */
      getTotalSelectedSections: (): number => get().selectedSections.length,

      /**
       * Updates a specific field's value using a unique key format: `headerId-labelId`.
       * Ensures uniqueness across sections.
       * @param {number} headerId - The ID of the section header.
       * @param {number} labelId - The ID of the field label.
       * @param {string} value - The input value for the field.
       */
      setFilledField: (
        headerId: number,
        labelId: number,
        value: string | string[]
      ) => {
        set((state) => {
          const fieldKey = `${headerId}-${labelId}`; // Unique key
          const updatedFields = {
            ...state.filledFields,
            [fieldKey]: Array.isArray(value)
              ? value.filter((v) => v.trim() !== "")
              : value,
          };

          return { filledFields: updatedFields };
        });

        // Recalculate completed sections after updating input
        get().updateFilledFields();
      },

      /**
       * Checks which sections are fully completed.
       * A section is marked as "completed" if all its required input fields have values.
       */
      updateFilledFields: () => {
        const { report, filledFields, selectedSections } = get();
        const completed = new Set<number>();

        ALWAYS_COMPLETED_SECTIONS.forEach((id) => {
          if (selectedSections.includes(id)) {
            completed.add(id);
          }
        });

        // Skip unselected sections
        report.forEach((tab) => {
          tab.fields.forEach((section) => {
            const { headerId } = section;

            if (
              selectedSections.includes(headerId) &&
              isSectionComplete(section, filledFields)
            ) {
              completed.add(headerId);
            }
          });
        });
        set({ completedSections: completed });
      },

      setSectionCompletion: (headerId: number, isComplete: boolean) => {
        set((state) => {
          const updatedCompletedSections = new Set(state.completedSections);

          if (isComplete) {
            updatedCompletedSections.add(headerId);
          } else {
            updatedCompletedSections.delete(headerId);
          }

          return { completedSections: updatedCompletedSections };
        });
      },
    }),
    { name: "report-storage" }
  )
);

/**
 * Finds a section by its header ID from the report.
 * @param {ReportSection[]} report - The report sections.
 * @param {number} sectionId - The header ID of the section to find.
 * @returns {ReportField | undefined} - The found section or undefined.
 */
const findSectionById = (report: ReportSection[], sectionId: number): ReportField | undefined => {
  return report.flatMap(tab => tab.fields).find(field => field.headerId === sectionId);
};

/**
 * Checks whether a specific label is filled.
 *
 * @param {number} headerId - The section's header ID.
 * @param {number} labelId - The field's label ID.
 * @param {Record<string, string | string[]>} filledFields - The object storing filled field values.
 * @returns {boolean} - Returns `true` if the field has a value, otherwise `false`.
 */
const isFieldFilled = (
  headerId: number,
  labelId: number,
  filledFields: Record<string, string | string[]>
): boolean => {
  const fieldKey = `${headerId}-${labelId}`;
  const value = filledFields[fieldKey];

  if (Array.isArray(value)) {
    return value.length > 0 && value.some((v) => v.trim() !== "");
  }

  return value !== undefined && value !== "";
};

/**
 * Determines if a section is complete based on its input fields.
 *
 * @param {ReportField} section - The report section containing subheaders and labels.
 * @param {Record<string, string | string[]>} filledFields - The object storing filled field values.
 * @returns {boolean} - Returns `true` if all real input fields in the section are filled.
 */
const isSectionComplete = (
  section: ReportField,
  filledFields: Record<string, string | string[]>
): boolean => {
  let hasRealInputs = false;
  let allRealInputsFilled = true;

  section.subheaders.forEach((sub) => {
    sub.labels.forEach((label) => {
      const isFilled = isFieldFilled(
        section.headerId,
        label.labelId,
        filledFields
      );

      if (label.labelId === 15) {
        if (Object.values(filledFields).some((val) => val !== "")) {
          hasRealInputs = true;
          allRealInputsFilled = allRealInputsFilled && isFilled;
        }
      } else {
        hasRealInputs = true;
        allRealInputsFilled = allRealInputsFilled && isFilled;
      }
    });
  });

  return hasRealInputs && allRealInputsFilled;
};
