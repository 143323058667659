import { useSaveReportStore } from "@/store/use-save-report-store";
import { API_LIST } from "@/utlity/api-list";
import { apiService } from "@/utlity/api-service";
import React from "react";

export const useSaveReport = () => {
  const { saveReportRequestBody } = useSaveReportStore();

  const saveReport = async () => {
    try {
      await apiService.post(API_LIST.SAVE_REPORT, saveReportRequestBody);
    } catch (error) {
      console.error("Error saving report:", error);
    }
  };

  return { saveReport };
};
