import { cn } from '@/lib/utils';
import { ModalProps } from '@/types/components';
import React from 'react';
import { createPortal } from 'react-dom';

const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    modalHeader = true,
    modalFooter = true,
    modalTitle = true,
    size = 'max-w-xl',
    modalMainClass = 'items-center',
    modalContentClass = 'max-h-[90vh] overflow-y-auto scrollBar',
    children,
    modalTitleContent,
    modalFooterContent,
    modalCloseButton,
    modalHeaderClass
}) => {
    if (!isOpen) return null;

    return createPortal(
        <div id="default-modal" className={cn("overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-[1000] flex justify-center w-full h-[100vh] bg-[rgba(255,255,255,0.95)] dark:bg-[rgba(0,0,0,0.71)]", modalMainClass)}>
            <div className={`relative px-3 w-full max-h-full ${size}`}>
                <div className="relative border bg-white dark:bg-gray-900 border-neutral-100 dark:border-gray-700 rounded-lg shadow-md">
                    {/* Modal Header */}
                    {modalHeader && (
                        <div className={cn("flex items-center justify-between px-4 py-3 rounded-t border-b-[1.5px] border-neutral-[#BCBEC3] dark:border-gray-700", modalHeaderClass)} >
                            {modalTitle && (
                                <h3 className="text-2xl font-bold text-neutral-800 dark:text-[#EFF0F1]">{modalTitleContent || 'Title'}</h3>
                            )}
                            {modalCloseButton && (
                                <button onClick={onClose} className="text-gray-500 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-50">&#x2715;</button>
                            )}
                        </div>
                    )}

                    {/* Modal Body */}
                    <div className={cn('relative bg-white dark:bg-gray-900 space-y-4 rounded-b-lg', modalContentClass)}>{children}</div>

                    {/* Modal Footer */}
                    {modalFooter && (
                        <div className="px-4 py-2 border-t border-neutral-100 dark:border-gray-700">{modalFooterContent || 'Footer'}</div>
                    )}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Modal;
