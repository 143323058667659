import { create } from "zustand";

interface ScrollSyncState {
  activeScrolledSectionId: number | null;
  scrollSource: string | null;
  isScrollSyncEnabled: boolean;
  toggleScrollSync: () => void;
  setActiveSectionId: (id: number | null, source: string) => void;
}

export const useScrollSync = create<ScrollSyncState>((set) => ({
  activeScrolledSectionId: 0,
  scrollSource: null,
  isScrollSyncEnabled: true,

  setActiveSectionId: (id, source) =>
    set({ activeScrolledSectionId: id, scrollSource: source }),

  toggleScrollSync: () =>
    set((state) => ({ isScrollSyncEnabled: !state.isScrollSyncEnabled, activeScrolledSectionId: 0 })),
}));
