import { cn } from "@/lib/utils";

export const ArrowDown = ({ className, stroke = "#3C3E43", width = "16", height = "16" }: { className?: string, stroke?: string, width?: string, height?: string }) => {
    return (
        <svg className={cn(className)} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Right-arrow">
                <path id="Vector" d="M5 7.5L10 12.5L15 7.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    )
}
